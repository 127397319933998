import styled from '@emotion/styled';
import { mqs } from './style-utils';
const setWordBreak = ({ lang }) => ['ja', 'zh'].includes(lang) ? 'break-all' : 'keep-all';
export const Image = styled.img `
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const Layout = styled.div `
  position: relative;
  padding-top: 8rem;

  ${mqs[0]} {
    padding-top: 6.5rem;
  }

  ${mqs[1]} {
    padding-top: 6.5rem;
  }
`;
export const SubTitle = styled.p `
  opacity: 0.6;
  font-size: 1.25rem;
  letter-spacing: 0.23px;
  line-height: 1.5;
`;
export const Header = styled.header `
  position: relative;
  margin-bottom: 5rem;
`;
export const H1 = styled.h1 `
  font-weight: bold;
  font-size: 2.5rem;
  text-transform: uppercase;
  max-width: 80%;
  color: ${({ theme }) => theme.fg};
  letter-spacing: -0.1rem;
  line-height: 1.1;
  hanging-punctuation: first;

  ${mqs[0]} {
    font-size: 2rem;
    max-width: 85%;
  }

  ${mqs[1]} {
    font-size: 2.25rem;
    max-width: 85%;
  }

  ${mqs[2]} {
    font-size: 3rem;
  }

  ${mqs[3]} {
    font-size: 3.25rem;
  }
`;
export const MarkdownStyler = styled.div `
  color: ${({ theme }) => theme.fg};
  position: relative;
  margin-top: 1.5rem;
  padding-bottom: 5rem;
  padding-right: 2rem;

  ${mqs[0]} {
    padding-right: 2.25rem;
  }

  ${mqs[1]} {
    padding-right: 2.25rem;
  }

  h1 {
    position: absolute;
    max-width: 10rem;
    opacity: 0.6;
    font-weight: bold;
    font-size: 1rem;
    padding-top: 0.25rem;
    text-align: right;
    left: -1.5rem;
    transform: translateX(-100%);

    ${mqs[0]} {
      font-size: 0.8rem;
      max-width: 5rem;
      padding-top: 0.4rem;
      word-break: break-word;
      left: -0.5rem;
    }

    ${mqs[1]} {
      font-size: 0.9rem;
      max-width: 7rem;
      padding-top: 0.3rem;
      word-break: break-word;
      left: -0.75rem;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
    font-size: 1rem;
    letter-spacing: 0.1px;
    font-weight: normal;
    font-weight: 400;
    line-height: 1.75;
    word-break: ${setWordBreak};
  }

  ol {
    list-style: none;
    counter-reset: ol-counter;

    li {
      position: relative;
      counter-increment: ol-counter;
      margin-bottom: 0.5rem;
      line-height: 1.4;
      word-break: ${setWordBreak};
    }

    li:before {
      position: absolute;
      content: counter(ol-counter);
      opacity: 0.4;
      font-size: 0.8em;
      font-weight: bold;
      left: -1rem;
    }
  }
`;
export const Hanger = styled.aside `
  position: absolute;
  left: -1.25rem;
  top: 0;
  line-height: 1.5;
  transform: translateX(-100%);
  text-align: right;
  max-width: 50%;
  opacity: 0.6;

  strong {
    font-weight: bolder;
  }

  p {
    transform: translateX(0.5rem);
    transition: transform 0.3s ease;
  }
  p:nth-of-type(2) {
    transition-delay: 50ms;
  }
`;
export const StyledArticle = styled.article `
  position: relative;

  img {
    object-fit: cover;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.fg};
  }

  &:hover ${Hanger} {
    p {
      transform: translateX(0);
    }
  }
`;
export const StyledInfo = styled.div `
  position: relative;
  h1 {
    font-size: 1.25rem;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 1.2;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  h1 + p {
    font-size: 0.8rem;
    line-height: 1.5;
    letter-spacing: 0.2px;
  }
`;
