import * as React from 'react';
import { css } from '@emotion/core';
export const Sizer = ({ ratio, children, ...props }) => {
    const height = 100 / ratio;
    return (<div {...props} 
    // @ts-ignore
    css={css `
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: ${height}%;
      `}>
      <div 
    // @ts-ignore
    css={css `
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        `}>
        {children}
      </div>
    </div>);
};
