/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import { graphql } from 'gatsby';
import pose from 'react-pose';
import SEO from '../components/seo';
import { FlexContent } from '../components/grid';
import { Sizer } from '../components/image-sizer';
import { H1, Image, Header, MarkdownStyler, Layout, SubTitle, } from '../components/template.common';
const TRANSITION = {
    ease: 'easeOut',
};
const PoseHeader = pose.div({
    enter: {
        delay: 150,
        opacity: 1,
        transition: TRANSITION,
    },
    exit: {
        opacity: 0,
        transition: TRANSITION,
    },
});
const PoseContent = pose.div({
    enter: {
        delay: 250,
        opacity: 1,
        y: 0,
        transition: TRANSITION,
    },
    exit: {
        opacity: 0,
        y: -5,
        transition: TRANSITION,
    },
});
const CompanyPage = ({ data, pageContext }) => {
    const { frontmatter, html } = data.company;
    const { title, subTitle, featureImage, description } = frontmatter;
    const { lang } = pageContext;
    return (<Layout>
      <PoseHeader>
        <Header>
          <FlexContent>
            <SEO title={'Always \'Be Happy\' With Us'} {...{
        lang,
        description,
    }}>
              <script type="application/ld+json">{`{
                  "@context": "https://schema.org",
                  "@type": "Organization",
                  "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "Seoul, South Korea",
                    "postalCode": "06051",
                    "streetAddress": "서울시 강남구 논현로 142길 35"
                  },
                  "email": "bhent(at)hanmail.net",
                  "faxNumber": "+82-2-542-3725",
                  "name": "BH Entertainment",
                  "telephone": "+82-2-542-3724"
                }`}</script>
            </SEO>
            <H1 dangerouslySetInnerHTML={{
        __html: title,
    }}/>
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
          </FlexContent>
        </Header>
      </PoseHeader>
      <PoseContent>
        <FlexContent>
          <Sizer ratio={2}>
            <Image src={featureImage || 'https://via.placeholder.com/1200x800'} alt={'BH Entertainment'}/>
          </Sizer>
        </FlexContent>
        <article>
          <FlexContent>
            <MarkdownStyler lang={lang} dangerouslySetInnerHTML={{
        __html: html,
    }}/>
          </FlexContent>
          <FlexContent>
            <a href="http://naver.me/5iYBdO8e" target="blank">
              <Sizer ratio={2}>
                <Image src={'/assets/map.jpg'} alt={'map'}/>
              </Sizer>
            </a>
          </FlexContent>
        </article>
      </PoseContent>
    </Layout>);
};
export default CompanyPage;
export const query = graphql `
  query CompanyPage($lang: String!) {
    company: markdownRemark(
      frontmatter: { type: { eq: "page/company" }, lang: { eq: $lang } }
    ) {
      html
      frontmatter {
        title
        subTitle
        featureImage
        description
      }
    }
  }
`;
